import React, { useContext } from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import SEO from "../../components/seo"
import Layout from "../../components/layout"

import { LangContext } from "../../context/langContext"

import { Breadcrumbs } from "../../components/breadcrumbs/breadcrumbs"
import { CardAwards } from "../../components/card/card"
import { CoverMain } from "../../components/cover-main/cover-main"
import { HeadingBasic } from "../../components/heading/heading"
import { Section, Container } from "../../components/grid/grid"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const Awards = ({ location }) => {
  const { lang } = useContext(LangContext)
  const data = useStaticQuery(graphql`
    query {
      imgCoverMain: file(relativePath: { eq: "img_development-main.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      wordPress {
        pageBy(uri: "about-us/awards") {
          title
          blocks {
            name
            ...CoreParagraphBlock
            ...CoreCoverBlock
            ...CoreGroupBlock
          }
          translation(language: ID) {
            title
            blocks {
              name
              ...CoreParagraphBlock
              ...CoreCoverBlock
              ...CoreGroupBlock
            }
            seo {
              title
              metaDesc
              twitterTitle
              twitterDescription
              opengraphTitle
              opengraphDescription
            }
          }
          seo {
            title
            metaDesc
            twitterTitle
            twitterDescription
            opengraphTitle
            opengraphDescription
          }
        }
      }
    }
  `)

  const wordPress =
    data?.wordPress?.pageBy?.translation?.blocks ||
    data?.wordPress?.pageBy?.blocks
  const seo =
    data?.wordPress?.pageBy?.translation?.seo || data?.wordPress?.pageBy?.seo
  const cover = wordPress.find(x => x.name === "core/cover")
  let paragraphs = []

  if (wordPress.filter(x => x.name === "core/paragraph").length > 0) {
    wordPress
      .filter(x => x.name === "core/paragraph")
      .map(item => paragraphs.push(item.attributes.content))
  }

  return (
    <Layout location={location} currentLocation={location.pathname} lang="ID">
      <SEO
        title={seo?.title}
        fbTitle={seo?.opengraphTitle}
        twitterTitle={seo?.twitterTitle}
        twitterDescription={seo?.twitterDescription}
        fbDescription={seo?.opengraphDescription}
        description={seo?.opengraphDescription}
        url={location?.href}
        fbImg={
          cover
            ? cover.attributes.url
            : data?.imgCoverMain?.childImageSharp?.fluid
        }
        twitterImg={
          cover
            ? cover?.attributes?.url
            : data?.imgCoverMain?.childImageSharp?.fluid
        }
        img={
          cover
            ? cover?.attributes?.url
            : data?.imgCoverMain?.childImageSharp?.fluid
        }
      />
      <Helmet>
        <body className="bd-page" />
      </Helmet>

      <CoverMain
        h1={
          data?.wordPress?.pageBy?.translation?.title ||
          data?.wordPress?.pageBy?.title
        }
        img={
          cover ? cover.attributes.url : data.imgCoverMain.childImageSharp.fluid
        }
        className="cover-md cover-detail"
      >
        <Breadcrumbs path={location.pathname} className="breadcrumb-light" />
      </CoverMain>

      <Section className="bg-light">
        <Container className="mw-lg">
          <HeadingBasic text={paragraphs} />
        </Container>
      </Section>

      {wordPress.map((item, i) => {
        if (item.name === "core/group") {
          return <CardAwards data={item} key={`${item.name}${i}`} />
        }
        return <div key={`empty-${i}`}></div>
      })}
    </Layout>
  )
}

export default Awards
